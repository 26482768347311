/* @import url('https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;500;600;700&display=swap'); */

@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800;900&display=swap");

@import "rsuite/dist/rsuite.css";

body {
    background: #f2f8fd;
    box-sizing: border-box;
    font-family: "Nunito", sans-serif !important;
    /* font-family: 'Outfit', sans-serif !important; */
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
a {
    font-family: "Nunito", sans-serif !important;
    /* font-family: 'Outfit', sans-serif !important; */
}

.btn {
    border-radius: 4px !important;
}

.btn-primary {
    background-color: #234094 !important;
    font-size: 16px;
}

.page-content {
    margin-top: 15px;
    padding-top: 50px;
    padding-bottom: 15px;
    padding-right: 5px;
    padding-left: 5px;
}

.card {
    /* border-radius: 15px;
box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
    margin-bottom: 0;
}

.card-body {
    padding: 12px;
}

.lg-form input {
    padding: 13.5px 15px;
    font-size: 12px;
    padding-left: 30px;
    border-radius: 4px;

    border: 1px solid #bcbcbc !important;
}

.lg-form .form-check input {
    border-radius: 4px;
    padding: unset;
    width: 13px;
    height: 13px;
}

.ic-input {
    position: absolute;
    top: 7px;
    left: 5px;
    font-size: 22px;
    color: #7e8295;
}

.position-y {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
}

.lg-ess h6 {
    cursor: pointer;
    font-size: 15px;
}

small {
    opacity: 0.8;
    font-weight: 500;
}

.ic-wrapper {
    align-items: center;
    background-color: #20365b;
    display: flex;
    height: 35px;
    justify-content: center;
    padding: 10px;
    width: 35px;
    color: #fff;
}

.modal-content {
    border-radius: 0.5rem;
}

.invalid-feedback {
    font-size: 100%;
}

.lp-wrapper {
    max-width: 523px;
    width: 100%;
    margin: auto;
}

p {
    color: #5b626b;
    line-height: 1.4;
}

body[data-sidebar="dark"] .vertical-menu,
body[data-sidebar="dark"] .navbar-brand-box {
    background: #002645;
}

body[data-sidebar="dark"] .mm-active .active,
body[data-sidebar="dark"] .mm-active .active i {
    background-color: #2f4d67;
    color: #fff !important;
}

.navbar-brand-box span.logo-lg img {
    -webkit-transform: scale(2.03);
    /* scale: 2.03; */
    margin-top: 123px;
    padding-left: 13px;
}

#sidebar-menu ul li a i {
    min-width: 35px;
    font-size: 20px;
}

#sidebar-menu ul li a {
    font-size: 15px;
    padding: 12.7px 12px;
    color: #fff !important;
    font-weight: 700 !important;
    display: flex;
    align-items: center;
}

#sidebar-menu ul li a img {
    margin-right: 12px;
}

.page-title-box {
    padding: 15px 0px;
}

.page-title-box .page-title {
    font-size: 32px;
    color: #000;
    margin-bottom: 5px;
}

.navbar-brand-box,
.vertical-menu {
    width: 200px;
}

.main-content {
    margin-left: 200px;
}

.vertical-collpsed .navbar-brand-box {
    padding: 0;
    width: 80px !important;
}

button#vertical-menu-btn {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 103px;
    margin-left: -41px;
    color: #d8dee2;
}

.app-search .form-control {
    height: 43px;
    background-color: #edeff1;
    margin-left: 10px;
}

.app-search span {
    font-size: 15px;
    line-height: 44px;
    color: #b2b2b2;
}

#page-topbar {
    background-color: #fff;
}

.header-profile-user {
    width: 43px;
    height: 43px;
}

.noti-icon .badge {
    position: absolute;
    top: 17px;
    left: 27px;
    width: 20px;
    height: 20px;
    border-radius: 50% !important;
    line-height: 1.4;
    border: 1px solid #fff;
}

.custom-hd-dd {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-direction: row-reverse;
}

.mini-stat .mini-stat-img img {
    max-width: 44px;
}

.footer {
    height: unset;
    left: 200px;
    background-color: #ffffff;
    padding: 10px;
}

#sidebar-menu {
    padding: 20px 0 30px 0;
}

button#vertical-menu-btn i {
    font-size: 14px;
}

body[data-sidebar="dark"] #sidebar-menu ul li a,
body[data-sidebar="dark"] #sidebar-menu ul li a i {
    color: #c8c8c8;
}

body[data-sidebar="dark"] #sidebar-menu ul li a:hover {
    background-color: #0e3a5f;
    color: #fff;
}

.pl-height {
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul>li>a i {
    font-size: 24px;
    margin-left: 2px;
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul>li>a {
    padding: 8px 12px;
}

.vertical-collpsed .vertical-menu {
    width: 80px !important;
}

.custom-pg_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.inner-pg_search input.form-control {
    margin-left: 0;
    background: #fff;
    height: 43px;
    border: 1px solid #e2e2e2;
    border-radius: 50px;
    padding-left: 15px;
}

.inner-pg_search span {
    top: 0px;
}

.offcanvas-body .form-control,
.select2-selection__control {
    border-radius: 4px !important;
    background: #ffffff !important;
    border: 1px solid #00103566 !important;
}

.rs-input-group.rs-input-group-inside {
    width: 325px;
    border-radius: 4px !important;
    border: 1px solid #00103566 !important;
    width: 48px;
    height: 48px;
}

.off-footer {
    position: fixed;
    right: 15px;
    bottom: 15px;
    padding-top: 15px;
}

button.btn.btn-primary {
    border-color: #002645;
    background: #002645;
    border-radius: 10px;
}

.text-primary,
td a,
footer a {
    color: #233c93 !important;
}

footer a {
    color: #233c93 !important;
    text-decoration: underline !important;
}

.btn.btn-outline-primary:hover {
    background: #253d91;
    color: #fff !important;
}

.badge {
    border-radius: 4px !important;
    padding-inline: 5px;
}

.ut_ic {
    flex-shrink: 0;
    width: 36px;
    height: 36px;
    background: #dee7ff;
    color: #868ca5;
    border-radius: 4px;
    text-align: center;
    font-size: 24px;
    line-height: 39px;
}

.status-wrapper {
    right: 0;
}

.utilities-wrapper h6 {
    font-size: 14px;
}

.utilities-wrapper p {
    margin-bottom: 2px;
}

/* width */
::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #1f9bd7;
    border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.kb-child {
    max-width: 20%;
    width: 100%;
    border-radius: 0px;
    padding: 5px;
    flex-shrink: 0;
    border-right: 1px solid #ddd;
}

.events .set1 {
    /* background: #fff; */
    padding: 10px;
    margin-top: 10px;
    border-radius: 4px;
    position: relative;
}

.post-float {
    position: absolute;
    top: -11px;
    right: 15px;
}

.events .set1 ul,
.ss-details ul {
    display: flex;

    flex-wrap: wrap;
}

.events .set1 li {
    padding: 5px 10px;
    border-radius: 20px;
    margin-right: 7px;
    margin-bottom: 7px;
}

.ss-details li {
    background-color: #fff;
    padding: 5px 10px;
    border-radius: 20px;
}

.question-count ul li {
    background-color: rgb(218, 231, 242);
    /* color: #fff; */
    color: #1a252f;
    cursor: pointer;
    padding: 0 !important;
    width: 25px;
    font-size: 10px;
    height: 18px;
    text-align: center;
    line-height: 2;
}

.session-details {
    height: calc(100vh - 306px);
    overflow: auto;
}

.events .set1:first-child {
    margin-top: 0;
}

.events {
    height: calc(100vh - 565px);
    overflow: auto;
    margin-top: 10px;
    padding-top: 0px;
    margin-bottom: 5px;
    min-height: 250px; /* use screen small below 1366*768*/
}

.nav-tabs-custom .nav-item .nav-link {
    padding: 3px;

}

.nav-tabs-custom .nav-item .nav-link.active {
    color: #022644;
}

.nav-tabs-custom .nav-item .nav-link::after {
    height: 4.5px;
    background: #03a900;
}

.nav-tabs-custom .nav-item .nav-link.active::before {
    content: "";
    position: absolute;
    width: 5px;
    height: 5px;
    background: #03a900;
    border-radius: 50%;
    top: -1px;
    right: 0px;
}

.nav-tabs>li>a,
.nav-pills>li>a {
    color: #848484;
}

.dropdown-menu[style].custom-position {
    right: 0 !important;
}

.events img {
    margin-top: -5px;
}

.btn-primary {
    background-color: #002645;
}

.border-right {
    border-right: 1px solid #939393;
}

.border-1 {
    border: 1px solid #939393;
}

/* KATHIR CSS START */
.headerlogin {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    text-align: center;
    background-color: #ffebcd;
    /* align-items: center; */
    border-radius: 5px;
    color: #000;
    /* display: flex; */
    font-size: 10px;
    font-weight: 400;
    /* justify-content: flex-start; */
    padding: 5px 20px;
}

.datetime {
    display: flex;
    flex: 1;
    justify-content: space-between;
}

.fc-daygrid-dot-event.fc-event-mirror,
.fc-daygrid-dot-event:hover {
    background: #233c93 !important;
}

.super-app-theme--header {
    background-color: #f2f8fd;
}

.MuiDataGrid-row {
    border: 1px solid #eff0f2;
}

.MuiDataGrid-cell:focus-within,
.MuiDataGrid-cell:focus,
.MuiDataGrid-columnHeader:focus-within,
.MuiDataGrid-columnHeader:focus {
    outline: none !important;
}

.MuiTablePagination-toolbar p {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

/* KATHIR CSS end */

.mini-stat img {
    height: auto;
    margin-right: 24px;
    object-fit: contain;
    width: 54px;
}

.mini-stat h5 {
    font-size: 18px;
    font-weight: 700;
}

.mini-stat h4 {
    font-size: 32px;
    font-weight: 700 !important;
    margin-top: 10px;
    margin-bottom: -3px !important;
}

.ft-session {
    align-items: center;
    border: 1px solid #a6a3a3;
    border-left: 4px solid #68ca89;
    border-radius: 4px;
    display: flex;
    flex-shrink: 0;
    height: 31px;
    min-width: 164px;
    width: fit-content;
    padding: 0px 12px;
    padding-left: 10px;
}

.cs-mark {
    align-items: center;
    background-color: #20365b;
    border-radius: 2px;
    color: #fff;
    display: flex;
    font-size: 14px;
    width: fit-content;
    height: 36px;
    justify-content: center;
    padding: 5px 10px;
    margin-top: -1px;
}

.navbar-brand-box,
.vertical-menu {
    padding: 16px;
}

.noti-icon i {
    font-size: 32px;
    color: #000 !important;
}

.notification-item i {
    font-size: 32px;
    color: #000;
}

.notification-item {
    position: relative;
}

div#sidebar-menu {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: 0;
}

.core1 {
    flex-grow: 1;
}

.simplebar-content {
    height: calc(100vh - 102px);
}

.custom-formatter {
    border: 1px solid hsla(0, 0%, 75%, 0.588);
    border-radius: 2px;
    display: flex;
    flex-direction: column;
}

.navbar-header {
    height: 48px;
}

#sidebar-menu {
    padding-top: 70px;
}

.vertical-collpsed #sidebar-menu {
    padding-top: 30px;
}

span.logo-sm {
    margin-top: 120px;
}

.vertical-collpsed button#vertical-menu-btn {
    margin-left: -50px;
}

.page-search {
    width: 844px;
    padding: 0;
}

.page-search.mylibary {
    width: 544px;
    padding: 0;
}

.page-search .form-control {
    background: #fff;
    border: 1.5px solid #e7e7e7;
    border-radius: 4px !important;
    font-size: 14px;
    height: 48px;
    line-height: 48px;
    margin-top: 1px;
    padding: 0 10px 0 40px;
    margin-left: 0;
}

.page-search span {
    left: 19px;
    top: 10px;
    font-size: 20px;
    color: #767676;
    line-height: 27px;
    width: fit-content;
}

.filter-child {
    max-width: 288px;
    width: 100%;
}

.filter-section {
    margin-top: 20px;
}

label {
    color: #000;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 3px;
    margin-left: 2px;
}

.fc-event,
.fc-event-dot {
    background-color: unset;
    border: 1px solid none;
    border-radius: 4px;
}

.fc-daygrid-dot-event.fc-event-mirror,
.fc-daygrid-dot-event:hover {
    background-color: unset !important;
}

.cl-card {
    background-color: #ffffff;
    color: #001035;
    border: 1px solid #b2b7c2;
    width: 100%;
    margin: auto;
    border-radius: 4px;
    text-align: left;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
    margin: 0;
    padding: 10px;
    width: 100%;
    display: block;
}

.cs-status {
    border: none;
    border-radius: 0 0 4px 4px;
    padding: 3px;
    font-size: 15px;
    line-height: 25px;
    margin-top: 7px;
    width: 100%;
    text-align: center;
    margin-bottom: 3px;
}

.st-upcoming {
    background-color: #5b51ff;
    color: #fff;
}

.st-completed {
    background: #d9f6ed;
    color: #237b56;
}

.st-ongoing {
    background: #ffe1b6;
    color: #7c4a00;
}

.phase-divider {
    width: 100%;
    height: 1px;
    background: #BFBFBF;
    margin: 10px 0;
}

.custom-lft {
    /* margin-left: 20px; */
    text-align: center;
    font-size: 10px;
    display: block;
    overflow: hidden;
}

table.fc-scrollgrid.table-bordered.fc-scrollgrid-liquid {
    background: #fff;
}

.fc-toolbar-chunk div {
    display: flex;
    align-items: center;
    margin-left: 10px;
}

button.fc-prev-button.btn.btn-primary,
button.fc-next-button.btn.btn-primary {
    align-items: center;
    background-color: rgba(0, 16, 53, 0.039) !important;
    color: rgb(0, 16, 53);
    border: none;
    display: flex;
    font-size: 12px;
    width: 36px;
    height: 40px;
    justify-content: center;
    padding: 0 10px;
}

button.fc-prev-button.btn.btn-primary {
    margin-right: 10px;
}

button.fc-next-button.btn.btn-primary {
    margin-left: 10px;
}

.fc-toolbar-chunk div:last-child {
    gap: 0;
}

button.fc-dayGridWeek-button.btn.btn-primary {
    border-top-right-radius: 30px !important;
    border-bottom-right-radius: 30px !important;
    font-size: 12px;
    background-color: unset !important;
    color: #20365b !important;
    border-color: #20365b !important;
    padding: 5px 12px;
    padding-left: 6px;
}

button.fc-dayGridDay-button.btn.btn-primary {
    border-top-left-radius: 30px !important;
    border-bottom-left-radius: 30px !important;
    font-size: 12px;
    background-color: unset !important;
    color: #20365b !important;
    border-color: #20365b !important;
    padding: 5px 12px;
}

button.fc-dayGridWeek-button.btn.btn-primary.active {
    background-color: #20365b !important;
    color: #fff !important;
    font-weight: 500 !important;
}

button.fc-dayGridDay-button.btn.btn-primary.active {
    background-color: #20365b !important;
    color: #fff !important;
    font-weight: 500 !important;
}

.fc-header-toolbar.fc-toolbar.fc-toolbar-ltr {
    margin-left: 50px;
    margin-top: -2px;
}

.custom-dp {
    position: absolute;
    background-color: #20365b;
    border-radius: 4px;
    margin-right: 12px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.page-title-box {
    padding: 0;
}

button.accordion-button {
    padding: 0;
    padding-inline: 10px;
    background-color: #b2b7c2;
    border-bottom: 1px solid #c4c4c4;
    cursor: pointer;
    font-size: 1.1rem;
    color: #1a1a1a;
    font-weight: 700 !important;
}

.accordion-button:not(.collapsed) {
    background-color: #b2b7c2;
}

.table tr th,
.table tr td {
    font-size: 15px !important;
    color: #000;
}

input.form-control,
textarea {
    border-color: #99a0ae !important;
}

.delete-pp h5 {
    font-size: 26px;
    font-weight: 700;
    margin-top: 20px;
}

.delete-pp p {
    font-size: 16px;
    font-weight: 600;
    margin-top: 10px;
}

.mm-icon i {
    font-size: 64px;
    margin-bottom: 20px;
}

.bck-btn {
    border: 0;
    background-color: #0010350a;
    cursor: pointer;
    height: 24px;
    object-fit: contain;
    padding: 4px;
    width: 24px;
    line-height: 0;
    margin-right: 10px;
    box-shadow: none;
}

.aw-button {
    align-items: center;
    color: #234094 !important;
    font-family: sans-serif;
    font-size: 18px;
    font-weight: 600 !important;
    border: 1px solid #234094;
    border-radius: 2px !important;
    cursor: pointer;
    display: flex;
    justify-content: center;
    margin-left: 10px;
    padding: 5px 10px;
}

.bg-success {
    background-color: rgb(101, 163, 13) !important;
    color: #fff;
}

.nav-tabs-custom .nav-item {
    position: relative;
    color: #2a3042;
    font-size: 18px;
    margin-right: 16px;
}

.nav-tabs-custom .nav-item a {
    font-weight: 700 !important;
}

.nav-tabs-custom .nav-item .nav-link.active {
    color: #022644;
    background: unset;
    padding: 3px;
    padding-bottom: 8px;
    font-weight: 800 !important;
}

.nav-tabs-custom {
    border-bottom: 1px solid #e0e2e7;
}

.bg-supermild {
    background-color: #fafbfd;
}

.btn-secondary {
    background-color: #e0e2e7;
}

.attempt3.bg-danger-subtle {
    background-color: rgb(255, 219, 219) !important;
}

.custom-success {
    color: #6bd5aa !important;
}

.attempt1.bg-success-subtle,
.attempt2.bg-success-subtle {
    background-color: rgb(217, 247, 237) !important;
}

li.lesson-bg-primary {
    background-color: rgb(32, 54, 91) !important;
}

.btn-secondary:active,
.btn-secondary:hover {
    background-color: unset !important;
}

.info {
    white-space: normal;
}

.ehp-w {
    flex: 1;
    max-width: unset;
}

/* .react-switch-bg {
height: 18px !important;
width: 30px !important;
}

.react-switch-handle {
height: 16px !important;
width: 16px !important;
transform: translateX(13px) !important;
} */

.rm-heignt {
    height: auto;
}

.ehp-ch_height {
    height: calc(100vh - 290px);
    overflow: auto;
}

.activity-wrapper .fo-right {
    width: 25%;
    flex-shrink: 0;
}

.activity-wrapper .fo-left {
    /* flex-grow: 1; */
    width: 75%
}

.fo-activity {
    padding-top: 33px;
    padding-bottom: 0;
}

.score-float {
    position: absolute;
    right: 35px;
}

table.table {
    vertical-align: middle;
}

.two_line-ellipse {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.circle {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #1a1a1a;
}

.close-icon {
    position: absolute;
    right: 10px;
    cursor: pointer;
}

.activities-cts {
    height: calc(100vh - 288px);
    border-bottom: 1px solid #1a1a1a;
}

.act-filters .my-ss {
    width: 188px;
}

.activity-scroll {
    height: calc(100vh - 407px);
    overflow: auto;
    margin-bottom: 20px;
}

.btn-cstm-float {
    position: absolute;
    right: 0px;
    bottom: 10px;
}

.loader-container.body {
    background-color: rgba(224, 240, 246, 0.7) !important;
    z-index: 500 !important;
}

.loader.box,
.loader.body {
    background-color: rgba(224, 240, 246, 0.7) !important;
    padding: unset !important;
    border-radius: unset !important;
}

.dropdown {
    z-index: 100 !important;
}

.datepickerHome .rs-input-group {
    width: unset !important;

}

.datepickerHome .rs-input-group .rs-input {
    display: none !important;
}

.datepickerHome {
    position: absolute;
    background-color: #20365b;
    border-radius: 4px;
    margin-right: 12px;
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    cursor: unset;
    margin-top: -6px;
}

.datepickerHome .rs-input-group .rs-input-group-addon {
    color: white !important;
    height: 100%;
    cursor: auto;
}

.datepickerHome .rs-input-group.rs-input-group-inside {
    border: 0 !important;

}

body[data-sidebar="dark"] .logo-light {
    text-align: left;
    padding-left: 16px;
}

.fc .fc-toolbar-title {
    font-size: 16px !important;
    font-weight: 400 !important;
    text-transform: capitalize !important;
    color: #212529 !important;
}

.cust-pd a {
    padding: 7px 12px !important;
}

.cust-rg-gutter {
    --bs-gutter-x: 12px !important;
    --bs-gutter-y: 12px !important;
}

.CustomWeeklyHeader_green_select__VupHs,
.CustomWeeklyHeader_p4__jzuP4 {
    min-width: 60px;
}

th.fc-col-header-cell.fc-day .fc-scrollgrid-sync-inner {
    padding: 20px 10px 10px 10px !important;
    text-align: left;
    font-weight: 800;
    font-size: 14px;
}

.fc .fc-daygrid-day.fc-day-today {
    background-color: unset !important;
    position: relative;
    border-top: 4px solid #d83d59;
}

.fc .fc-daygrid-day.fc-day-today::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 4px;
    background-color: #d83d59;
    top: 0;
    z-index: 9999;
}

.fc .fc-toolbar {
    align-items: unset !important;
}

button#page-header-notifications-dropdown {
    margin-right: 8px;
}

.fc .fc-highlight {
    background-color: unset !important;
}

.profile-wrapper {
    display: flex;
}

/*replace icon to img in datepicker*/
.datepickerHome .rs-picker-caret-icon {
    display: none;
    /* Hide the default icon */
}

.datepickerHome .rs-input-group-addon {
    background-image: url(../src/assets/images/services-icon/dp.svg) !important;
    background-color: unset !important;
    background-size: 100% !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    position: relative;
}

.fc-toolbar-chunk {
    margin-right: 8px;
}

.filter-section .rs-input-group.rs-input-group-inside {
    width: 325px;
    height: 38px;
}
.filter-libary .rs-input-group.rs-input-group-inside {
    width: 325px;
    height: 49px;
}
.filter-btn {
    background-color: #234094 !important;
    border: 1px solid #fff !important;
    border-radius: 5px !important;
    color: #fff !important;
    padding: 5px 10px !important;
    margin-left: 5px;
    margin-top: 24px !important;
}

li.cust-pd img {
    margin-right: 3px;
}

.cp-pd-0 {
    padding-top: 0 !important;
}

.luminous-wht {
    color: #8d9fb6;
}

.fc-daygrid-day-frame.fc-scrollgrid-sync-inner {
    position: relative;
}

.sidebar-enable .custom-formatter {
    border: 0;
}

.sidebar-enable .custom-formatter li.cust-pd {
    padding: 0;
}

.sidebar-enable .custom-formatter li.cust-pd a {
    padding: 0 !important;
    padding-top: 0 !important;
    margin-left: 10px;
    min-height: unset !important;
    height: unset !important;
    margin-bottom: 5px;
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul>li>a {

    min-height: unset !important;
    height: 44px;
}

.vertical-collpsed .main-content {
    margin-left: 80px;
}

.tb-bdge {
    border-radius: 2px !important;
    font-size: 12px !important;
    letter-spacing: .36px;
    line-height: 1;
    min-width: auto !important;
    padding: 10px !important;
}

.tb-success {
    background-color: #65A30C !important;
    color: #fff;
}

.tb-success:hover {
    color: #fff;
}

.tb-primary {
    background-color: #5B51FF !important;
}
.tb-danger {
    background-color: #d83d59;
}

.tb-warning {
    background-color: #EA580D !important;
}

.main-table tr td {
    border-bottom: 1px solid #c4c4c4;
}

::placeholder {
    color: #505050 !important;
    opacity: 1;
    /* Firefox */
    font-weight: 600;
}

.lg-ess {
    margin-top: 20%;
}

.cs-mg {
    margin-top: -2px;
    padding-right: 5px;
}

.inner-bd {
    height: 28px;
    align-items: center;
    border-radius: 5px !important;
    display: flex;
    justify-content: center;
    padding: 5px 10px;
    font-size: 12px;
}

.ut-pd {
    padding: 10px 20px !important;
}

.custom-me {
    margin-right: 24px;
}

.fw-100 {
    font-weight: 100 !important;
}

.fw-200 {
    font-weight: 200 !important;
}

.fw-300 {
    font-weight: 300 !important;
}

.fw-400 {
    font-weight: 400 !important;
}

.fw-500 {
    font-weight: 500 !important;
}

.fw-600 {
    font-weight: 600 !important;
}

.fw-700 {
    font-weight: 700 !important;
}

.fw-800 {
    font-weight: 800 !important;
}

.fw-900 {
    font-weight: 900 !important;
}

.me-10 {
    margin-right: 10px !important;
}

.me-12 {
    margin-right: 12px !important;
}

.me-05 {
    margin-right: 5px !important;
}

.me-20 {
    margin-right: 20px !important;
}

.me-22 {
    margin-right: 22px !important;
}

.ms-22 {
    margin-left: 22px !important;
}

.mt-5 {
    margin-top: 5px !important;
}

.mt-02 {
    margin-top: 2px !important;
}

.mt-10 {
    margin-top: 10px !important;
}

.mt-18 {
    margin-top: 18px !important;
}

.mt-20 {
    margin-top: 20px !important;
}

.mt-30 {
    margin-top: 30px !important;
}

.mt-40 {
    margin-top: 40px !important;
}

.mt-50 {
    margin-top: 50px !important;
}

.mt-60 {
    margin-top: 60px !important;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.mb-18 {
    margin-bottom: 18px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.mb-40 {
    margin-bottom: 40px !important;
}

.mb-50 {
    margin-bottom: 50px !important;
}

.mb-60 {
    margin-bottom: 60px !important;
}

.mr-10 {
    margin-right: 10px !important;
}

.mr-15 {
    margin-right: 15px !important;
}

.mr-18 {
    margin-right: 18px !important;
}

.mr-20 {
    margin-right: 20px !important;
}

.mr-30 {
    margin-right: 30px !important;
}

.mr-40 {
    margin-right: 40px !important;
}

.mr-50 {
    margin-right: 50px !important;
}

.mr-60 {
    margin-right: 60px !important;
}

.pd-10 {
    padding: 10px !important;
}

.cs-txt-danger {
    color: #fb0000 !important;
}

.btn.btn-secondary {
    align-items: center;
    background-color: #e0e2e7;
    border-radius: 5px !important;
    cursor: pointer;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    padding: 10.5px;
    /* width: 100%; */
    font-size: 15px;
    font-weight: 500;
}

.font-size-10 {
    font-size: 10px;
}

.bg-transparent {
    background-color: unset !important;
    background: unset !important;
    border: 0 !important;
}

.session-details .dropdown-item {
    padding: 0;
    margin-left: 3px;
    padding: 3px 0;
}

.session-details .dropdown-menu.show {
    border: 1px solid rgba(0, 0, 0, .175);
    border-radius: 0.375rem;
    font-size: 10px;
    font-weight: 400;
    padding: 0;
}

.text-dark {
    color: #000 !important;
    opacity: 1 !important;
}

.btn-primary.filter-btn.mt-0 {
    margin-top: 0 !important;
}

.hgt {
    height: 22px;
    display: flex;
    align-items: center;
}

.w-70 {
    width: 70% !important;
}

.w-80 {
    width: 80% !important;
}

.w-85 {
    width: 85% !important;
}

.w-90 {
    width: 90% !important;
}

.bc-bg {
    background: #0010350a;
    border-radius: 8px;
    cursor: pointer;
    height: 20px;
    margin-right: 10px;
    padding: 4px;
    width: 20px;
}

.cp-wd {
    width: 150px;
}

.cp-2 {
    height: 40px;
    box-shadow: 0 1px 8px 0 #4848481a;
}

.mb-02 {
    margin-bottom: 2px !important;
}

.mb-12 {
    margin-bottom: 12px !important;
}

.px-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
}

.chart-wrapper {
    padding: 16px;
    background: #fff;
    border: 1px solid rgba(0, 16, 53, .122);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    margin: 15px 0 0;
}

.pr-wrapper {
    display: flex;
}

.vertical-divider {
    border: 1px solid #e2e9f0;
    height: 100vh;
}

.profile-detail-wrap {
    width: 404px;
}

.pd-12 {
    padding: 12px !important;
}

.pdt-15 {
    padding-top: 15px !important;
}

.st-wrapper {
    padding: 0 12px;

}

.fo-pd {
    padding-top: 33px;
    padding-bottom: 20px;
}

.inner-pg-title {
    color: #000;
    font-family: sans-serif !important;
    font-size: 32px;
    font-style: normal;
    font-weight: 700 !important;
    line-height: 32px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 30vw;
}

.fs-sans-s {
    font-family: sans-serif !important;
}

.bg-mild-purple {
    background-color: #F0F3FB;
}

.bc-bg2 {
    background: #0010350a;
    border-radius: 8px;
    cursor: pointer;
    height: 24px;
    object-fit: contain;
    padding: 4px;
    width: 24px;
}

.obj-fit-cover {
    object-fit: cover;
}

.cp-bdg {
    padding: 5px 3px;
    border-radius: 5px;
}

.state-bg {
    background-color: #fafbfd !important;
}

.rm-height {
    height: unset !important;
}

.st-pd {
    padding: 12px 12px 8px;
}

.tp-height {
    min-height: 60px !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding: 5px;
    border: 1px solid #4F4F4F;
}

.sw-child {
    -webkit-transform: scale(.85);
    scale: 0.65;
    display: inline-block;
    margin-left: -5px;
}

.switch-wrapper {
    display: flex;
    align-items: center;
}

.btn.btn-secondary {
    background-color: #e0e2e7 !important;
}

button.bt-pg-bk-btn.btn.btn-secondary {
    background-color: #fff !important;
    border: 1px solid #d83d59;
    border-radius: 5px;
    color: #d83d59;
    font-size: 15px;
    margin-right: 10px;
    min-width: 100px;
    padding: 11px 48px;
}

button.bt-pg-sb-btn.btn.btn-secondary {
    background-color: #d83d59 !important;
    border: 1px solid transparent;
    border-radius: 5px;
    color: #fff;
    font-size: 15px;
    min-width: 100px;
    padding: 11px 48px;
}

.status-ac {
    width: fit-content;
    align-items: center;
    border-radius: 2px;
    display: flex;
    gap: 10px;
    padding: 10px;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
}

.attempted {
    background: rgb(101, 163, 13);
    color: #fff;
}

.unattempted {

    background: rgb(229, 231, 235);
}

.activity-wrapper table.table tbody tr th {
    padding: 10px !important;
}

.activity-wrapper .fo-left .btn-group {
    align-items: center;
}

.activity-wrapper .fo-left .btn-group button.btn.btn-danger {
    align-items: center;
    background: rgb(216, 61, 89);
    border-radius: 2px !important;
    cursor: pointer;
    display: flex;
    gap: 10px;
    justify-content: center;
    padding: 10px;
}

.activity-wrapper .fo-left .btn-group button.btn.btn-outline-danger {
    align-items: center;
    background-color: #fff !important;
    border: 1px solid rgb(216, 61, 89);
    border-radius: 2px !important;
    cursor: pointer;
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-right: 0px;
    padding: 10px;
    margin-bottom: 0;
}

.activity-wrapper {
    gap: 15px;
}

.cb-secondary.btn.btn-secondary {
    background: #808080 !important;
    color: #fff !important;
    font-size: 16px !important;
}

.btn-lg.cst-danger.btn.btn-danger {
    background: #d83d59 !important;
    border: 0;
    border-radius: 4px;
    box-shadow: 0 4px 35px 4px #2b489c1a;
    font-size: 16px !important;
    margin-bottom: 10px;
}

.filter-section.pymts .rs-input-group.rs-input-group-inside {
    width: 100%;
}

.ct-bag {
    border-radius: 2px;
    font-size: 12px !important;
    letter-spacing: .36px;
    line-height: 1;
    min-width: auto !important;
    padding: 10px !important;
    text-transform: uppercase;
}

.cts-btn-danger {
    background-color: #d83d59;
    border: 1px solid #d83d59;
    border-radius: 2px;
    color: #fff;
    font-size: 15px;
    line-height: 1;
    height: 100%;
    outline: none;
    padding: 15.5px 10px;
}

.table.cp-cs-pd tr th,
.table.cp-cs-pd tr td {
    padding: 6px 10px !important;
    word-wrap: break-word;
    word-break: break-word;
    white-space: normal;
    overflow: visible;
}

.cs-lp-width {
    max-width: 766px !important;
    margin: auto;
}

.cs-lp-width .modal-content {
    padding: 1.5rem;
}

.my-terms p {
    color: #212529 !important;
    margin-bottom: 16px;
}

.px-05 {
    padding-right: 5px !important;
    padding-left: 5px !important;
}

.info span i.fa.fa-circle {
    font-size: 5px !important;
}

.info span {
    margin-top: -2px;
}

p.calendar-empty {
    margin-top: 100%;
    white-space: normal;
}

.fc-dayGridDay-view.fc-view.fc-daygrid table.fc-scrollgrid.table-bordered.fc-scrollgrid-liquid {
    width: 240px;
}

.mastery-modal {
    max-width: 664px;
    margin: auto;
}
.mastery-modal1 {
    max-width: 1364px;
    margin: auto;
}

.mastery-modal_body p {
    font-size: 12px;
}

.good-wrapper p,
.warning-wrapper p,
.critical-wrapper p,
.disabled-wrapper p,
.child-wrapper p {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
}

.state-good {
    color: rgb(3, 169, 0);
}

.state-warning {
    color: rgb(246, 145, 30);
}

.state-critical {
    color: rgb(251, 0, 0);
}

.mr-3 {
    margin-right: 12px;
}

.nav-tabs-custom.activitytb .nav-item .nav-link.active::before {
    display: none;
}

.edit-float {
    position: absolute;
    bottom: 20px;
    background: #ccc;
    border-radius: 50px;
    padding: 2px 4px;
    position: absolute;
    right: 15px;
}

.rs-picker-daterange-panel.rs-picker-daterange-panel-show-one-calendar {
    max-width: 264px !important;
}

button.accordion-button {
    padding-right: 10px;
    padding-left: 10px;
}

.rs-anim-fade.rs-anim-in.rs-picker-daterange-menu.rs-picker-popup.placement-bottom-start {
    /* width: 255px; */
    width: 100 !important;
}

@media (max-width: 1600px) {
    .mini-stat h5 {
        font-size: 16px !important;
    }
}

@media (max-width: 1400px) {
    .mini-stat h5 {
        font-size: 14px !important;
    }
}

.w-60 {
    width: 60%;
}

.text-yellow {
    color: rgb(254 240 138);
}

.remove-bd .modal-content {
    border: 0 !important;
}

.hw-list ul li,
.hw-list ul a {
    background: #fff;
    padding: 25px;
    padding-bottom: 30px;
    box-shadow: rgb(214, 221, 227) 1px 0px 0px, rgb(214, 221, 227) 0px 1px 0px, rgb(214, 221, 227) -1px 0px 0px, rgb(214, 221, 227) 0px -1px 0px, rgba(63, 63, 68, 0.15) 0px 1px 3px;
    position: relative;
    margin-bottom: 25px;
}

ul.dg-selector li {
    padding: 0;
    display: flex;
    align-items: center;
    border-radius: 4px;
}

li.selector-wrong {
    color: #ae534f;
    background: #ffdbdb !important;
    border: 2px solid #ec6b66;
    display: block;
    box-shadow: 0px 0px 0px 4px #ffdbdb !important;
}

.border-start-danger {
    border-left: 1px solid #ec6b66;
}

li.selector-warning {
    color: #237b56;
    background: #d9f7ed !important;
    border: 2px solid #f8b424;
    display: block;
    box-shadow: 0px 0px 0px 4px #ffdbdb !important;
}

.border-start-warning {
    border-left: 1px solid #f8b424;
}

.border-start-secondary {
    border-left: 1px solid #b5c4d7;
}

li.selector-success {
    color: #237b56;
    background: #d9f7ed !important;
    border: 2px solid #63d3a6;
    display: block;
    box-shadow: 0px 0px 0px 4px #d9f7ed !important;
}

.border-start-success {
    border-left: 1px solid #63d3a6;
}

.border-start-secondary {
    border-left: 1px solid #b5c4d7;
}

li.selector-display {
    color: #20365B;
    border: 2px solid #20365B;
    display: block;
    box-shadow: 0px 0px 0px 4px #A9BFE9 !important;
}

.border-start-display {
    border-left: 1px solid #20365B;
}

button.btn.cs-btn-danger.mt-1.btn.btn-secondary {
    background: #d83d59 !important;
    color: rgb(255, 255, 255) !important;
    box-shadow: rgb(188, 50, 69) 0px 4px 0px, rgba(146, 45, 63, 0.5) 0px 8px 12px !important;
    padding: 0.5rem 1.225rem !important;
    font-size: 1.225rem !important;
    font-weight: 800 !important;
    border-radius: 2rem !important;
    letter-spacing: 0.05em !important;
    text-transform: uppercase !important;
    border: 0;
    /* width: 100%; */
}

ul.md-prev-tab li a.active {
    color: #FFFFFF !important;
    background: #20365B !important;
    box-shadow: 0 0 0 4px #A9BFE9;
    font-size: 24px;
    min-width: 52px;
    font-weight: 800;
    padding-left: 12px;
    padding-right: 12px;
    padding: 0;
    text-align: center;
    border-radius: 30px;
}

ul.md-prev-tab li {
    color: #B5C4D7;
    background: #DAE7F2;
    font-size: 24px;
    height: 32px;
    min-width: 52px;
    font-weight: 800;
    padding-left: 12px;
    padding-right: 12px;
    padding: 0;
    text-align: center;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
}

ul.md-prev-tab {
    border: 0;
}

/*120324 - surya*/


.selector-display-parttype-2 {
    background: #8fbc8f !important;
    border-color: #8fbc8f !important;
    color: #20365b;
}


.worksheet-width-hold {
    max-width: 1120px;
    width: 100%;
    margin: auto;
}

/* canvas#fabric-canvas-1,
.canvas-container {
    width: 100% !important;
} */

.border-thick {
    border: 10px solid #eee;
}

.tool-bar-worksheet {
    background-color: rgb(255, 255, 255);
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
    overflow: hidden;
    padding: 1rem .75rem;
    margin-top: 0 !important;
}

.cs-tools button,
.fo-select {
    margin-right: 0.5rem;
    height: 40px;
    width: 40px;
    background: none;
    border: 0;
    display: flex;
    align-items: center;
}

.fs-circle {
    width: 24px;
    height: 24px;
    border: 2px solid #2a2a2a;
    border-radius: 50%;
}

.fs-rectangle {
    width: 24px;
    height: 18px;
    border: 2px solid #2a2a2a;
}

.fs-square {
    width: 24px;
    height: 24px;
    border: 2px solid #2a2a2a;
}

.fo-select button {
    margin-right: 5px;
    padding: 0;
    display: block;
    height: unset;
    width: unset;
}

.fo-select {
    display: flex;
    align-items: center;
    margin-right: 0.9rem;
}

.fo-select select {
    width: 15px !important;
    border: 0;
}

.cs-tools svg {
    width: 26px;
    height: 22px;
}

.super-size {
    width: 30px !important;
    height: 38px !important;
}

.color-picker {
    height: 26px;
    width: 28px;
    margin-right: 1rem;

}

.color-picker input {
    width: 100%;
    height: 100%;
}

.fx-printer {
    width: 26px !important;
    height: 34px !important;
    margin-top: 2px;
}

.cs-tools button.active,
.fo-select.active {
    background: #f2f8fd !important;
}

.manual_mark_div {
    /* background: border-box; */
    /* background-color: #bbd9d9; */
    align-items: center;
    background-color: #a9bfe9;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    flex-direction: row;
    padding: 8px 10px;
    -webkit-transform: translate(-10px, 10px);
    transform: translate(-10px, 10px);
    width: calc(100% + 20px);
}

.password-hider {
    position: absolute;
    right: 22px;
    top: 10px;
    cursor: pointer;
}

.password-hider i {
    color: #4c4c4c;
}

.lg-userDetails {
    align-items: center;
    background-color: #fff;
    border: 1px solid #898c96;
    border-radius: 16px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .25);
    display: inline-flex;
    height: 100%;
    justify-content: center;
    width: 100%;
}

.bg-dark-blue {
    background-color: #022644 !important;
    margin-top: 50px;
}

.summary-width-hold {
    margin: auto;
    max-width: 1024px;
}

.assessement-section .nav-tabs-custom {
    border: 0;
}

ul.nav-tabs-custom {
    width: 90%;
}

.assessement-section li.nav-item {
    background: #dae7f2;
    color: #b5c4d7;
    margin-right: 20px;
    font-size: 24px;
    height: 35px;
    width: 52px;
    border-radius: 16px;
    text-align: center;
    }
    
    .assessement-section .nav-tabs-custom {
    border: 0;
    }
    
    .assessement-section .nav-tabs-custom .nav-item .nav-link::after {
    height: 0;
    }
    
    .assessement-section .nav-tabs-custom .nav-item .nav-link.active {
    background-color: unset;
    border: 2px solid #20365b;
    font-size: 24px;
    height: 35px;
    width: 100%;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    }
    
    .assessement-section .nav-tabs-custom .nav-item .nav-link{
    font-weight: 700;
    padding: 0;
    }
    
    .display-attempt2 {
    background: #d9f7ed !important;
    height: 35px;
    width: 52px;
    border-radius: 16px !important;
    }
    
    .display-attempt1 {
    background: #d9f7ed !important;
    color: #63d3a6;
    height: 35px;
    width: 52px;
    border-radius: 16px !important;
    }
    
    .display-attempt_fail {
    background: #ffdbdb !important;
    color: #ec6b66;
    height: 35px;
    width: 52px;
    border-radius: 16px !important;
    }
    
    .display-hold_active {
    color: #ffffff !important;
    background: #20365b !important;
    box-shadow: 0 0 0 4px #a9bfe9;
    height: 35px;
    border-radius: 16px !important;
    }
    
    .assessment-input {
    min-height: 45px;
    width: 200px;
    max-width: 300px;
    padding: 3px 7px !important;
    border: 1px solid gray;
    }
    
    .worng-ans {
    background: #ffdbdb;
    border: 2px solid #ec6b66;
    border-radius: 3px;
    box-shadow: 0 0 0 4px #ffdbdb;
    margin-bottom: 10px;
    }
    
    .correct-ans {
    background: #d9f7ed;
    border: 2px solid #63d3a6;
    border-radius: 3px;
    box-shadow: 0 0 0 4px #d9f7ed;
    margin-bottom: 10px;
    }
    
    .navigator-nxt {
    width: 380px;
    display: flex;
    padding: 30px;
    position: relative;
    background: #ffffff;
    align-items: center;
    border-radius: 24px !important;
    margin-bottom: 12px;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
    rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
    margin-left: auto;
    }
    
    .navigator-wrapper {
    display: flex;
    justify-content: flex-end;
    }
    
    .nv-dg-smile {
    position: absolute;
    }
    
    .nv-dg-smile img {
    width: 190px;
    }
    
    .nv-dg-smile {
    position: absolute;
    right: 340px;
    bottom: 0px;
    z-index: 9;
    }
    
    .navigator-wrapper::before {
    content: "";
    background-image: url(../src/assets/images/services-icon/curve-lft.png);
    position: absolute;
    z-index: 999;
    top: 30px;
    right: 380px;
    width: 40px;
    height: 40px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right bottom;
    }
    
    .btn-cst-success-md {
    padding: 0.25rem 1.5rem;
    border: none;
    color: rgb(255, 255, 255) !important;
    box-shadow: rgb(75, 185, 141) 0px 4px 0px !important;
    background-color: #63d3a6 !important;
    letter-spacing: 0.05em;
    text-transform: uppercase !important;
    border-radius: 8px !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    }
    
    .btn-cst-danger-md {
    padding: 0.25rem 1.5rem;
    border: none;
    color: rgb(255, 255, 255) !important;
    box-shadow: rgb(188, 50, 69) 0px 4px 0px, rgba(146, 45, 63, 0.5) 0px 8px 12px !important;
    background-color: #d83d59 !important;
    letter-spacing: 0.05em;
    text-transform: uppercase !important;
    border-radius: 8px !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    }
    
    .btn-cst-secondary-md {
    padding: 0.25rem 1.5rem;
    border: none;
    color: #8d9fb6 !important;
    box-shadow: hsl(207.5deg 48% 90.2%) 0px 4px 0px !important;
    background-color: #f2f8fd !important;
    letter-spacing: 0.05em;
    text-transform: uppercase !important;
    border-radius: 8px !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    }
    
    ul.dg-selector li {
    padding: 0;
    display: flex;
    align-items: center;
    border-radius: 4px;
    }
    
    li.selector-wrong {
    color: #ae534f;
    background: #ffdbdb !important;
    border: 2px solid #ec6b66;
    display: block;
    box-shadow: 0px 0px 0px 4px #ffdbdb !important;
    }
    
    .border-start-danger {
    border-left: 1px solid #ec6b66;
    }
    
    li.selector-warning {
    color: #237b56;
    background: #d9f7ed !important;
    border: 2px solid #f8b424;
    display: block;
    box-shadow: 0px 0px 0px 4px #ffdbdb !important;
    }
    
    .border-start-warning {
    border-left: 1px solid #f8b424;
    }
    
    .border-start-secondary {
    border-left: 1px solid #b5c4d7;
    }
    
    li.selector-success {
    color: #237b56;
    background: #d9f7ed !important;
    border: 2px solid #63d3a6;
    display: block;
    box-shadow: 0px 0px 0px 4px #d9f7ed !important;
    }
    
    .border-start-success {
    border-left: 1px solid #63d3a6;
    }
    
    .border-start-secondary {
    border-left: 1px solid #b5c4d7;
    }
    
    .emotion-wrapper ul li {
    padding: 0;
    border: 0 !important;
    box-shadow: none !important;
    margin-bottom: 0;
    position: relative;
    }
    
    .emotion-wrapper ul {
    display: flex;
    justify-content: center;
    gap: 40px;
    }
    
    .subject-float {
    position: absolute;
    width: 50%;
    }
    
    .subject-float {
    position: absolute;
    width: 110px;
    left: 50%;
    transform: translate(-50%);
    top: 60px;
    }
    
    .video-wrapper div {
    width: 100% !important;
    height: 550px !important;
    }
    
    .cursor-pointer {
    cursor: pointer;
    }

    .manual-marking_section {
        position: relative;
        z-index: 1;
        flex-shrink: 0;
    }

    .mark-set .form-check .form-check-input {
        width: 16px;
        height: 16px;
        border: 1px solid;
        margin-top: 1px;
    }

    .mark-set{
        margin-bottom: 20px;
    }



/*kathir add css */

 
.lb-details {
    border: 1px solid rgb(181, 196, 215);
    }
    
    .hw-icon {
    background-color: #f2f8fd;
    width: 3rem;
    height: 3rem;
    }
    
    .hw-list ul li,
    .hw-list ul a {
    background: #fff;
    padding: 25px;
    padding-bottom: 30px;
    box-shadow: rgb(214, 221, 227) 1px 0px 0px, rgb(214, 221, 227) 0px 1px 0px,
    rgb(214, 221, 227) -1px 0px 0px, rgb(214, 221, 227) 0px -1px 0px,
    rgba(63, 63, 68, 0.15) 0px 1px 3px;
    position: relative;
    margin-bottom: 25px;
    }
    
    .hw-icon {
    background-color: #f2f8fd;
    width: 50px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 40px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    }
    
    .ph_list-progressbar {
    position: absolute;
    width: 100%;
    bottom: 0px;
    left: 0;
    }
    
    .cs-btn-danger {
    background: #d83d59;
    color: rgb(255, 255, 255) !important;
    box-shadow: rgb(188, 50, 69) 0px 4px 0px, rgba(146, 45, 63, 0.5) 0px 8px 12px !important;
    padding: 0.5rem 1.225rem !important;
    font-size: 1.225rem !important;
    font-weight: 800 !important;
    border-radius: 2rem !important;
    letter-spacing: 0.05em !important;
    text-transform: uppercase !important;
    }
    
    .cs-btn-danger:hover {
    background-color: #d83d59 !important;
    }
    
    .ucs-status {
    color: #8d9fb6;
    background: #f2f8fd;
    height: 48px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    font-size: 16px;
    }
    
    .ucs-hz-list {
    padding: 24px;
    background: #fff;
    box-shadow: 1px 0px 0px #d6dde3, 0px 1px 0px #d6dde3, -1px 0px 0px #d6dde3,
    0px -1px 0px #d6dde3, 0px 1px 3px rgba(63, 63, 68, 0.15);
    margin-bottom: 5px;
    border-radius: 4px;
    }
    
    button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left {
    height: 100%;
    width: 100px;
    left: 0;
    background: linear-gradient(87deg, rgb(242, 248, 253) 32.41%, rgb(255 255 255 / 20%) 100%);
    color: #000;
    top: -2px;
    border-radius: 0 !important;
    }
    
    button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {
    height: 100%;
    width: 100px;
    right: 0;
    background: linear-gradient(
    270deg,
    rgb(240 246 251) 32.41%,
    rgb(255 255 255 / 62%) 100%
    );
    color: #000;
    top: -2px;
    border-radius: 0 !important;
    }
    
    .react-multiple-carousel__arrow::before,
    .react-multiple-carousel__arrow::after {
    color: #000;
    }
    
    .react-multi-carousel-track {
    gap: 20px;
    }
    
    .pg-title {
    font-size: 30px;
    font-weight: 800;
    color: #fff;
    text-align: center;
    }
    
    .cs-mt {
    margin-top: 80px;
    }
    
    .clsses-fview-list {
    display: flex;
    justify-content: center;
    margin-top: 40px !important;
    max-width: 1280px;
    flex-wrap: wrap;
    margin: auto;
    padding-bottom: 100px;
    }
    
    .clsses-fview-list .ucs-hz-list {
    width: 33.33%;
    margin-right: 24px;
    margin-bottom: 24px;
    }
    
    .ucs-attended {
    color: #237b56;
    background: #63d3a6;
    font-weight: 700;
    }
    
    .ucs-ongoing {
    color: #e68a2d;
    background: #f9d49d;
    font-weight: 700;
    }
    
    .ucs-missed {
    color: #ec6b66;
    background: #ffdbdb;
    font-weight: 700;
    }
    
    .list-bg {
    background-image: url(../src/assets/images/services-icon/bg-curve.svg);
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100%;
    min-height: 100vh;
    }
    
    .cl-pr-profile {
    position: relative;
    padding: 2rem 5rem 2rem 5rem;
    }
    
    .pr-name {
    background: #a9bfe9;
    border-radius: 4px;
    box-shadow: 0 2px 0 0 #6985bb;
    color: #20365b;
    font-size: 12px;
    font-weight: 600;
    left: 0;
    bottom: 100px;
    letter-spacing: 0.36px;
    line-height: 13px;
    position: absolute;
    right: 0;
    width: 100%;
    text-align: center;
    padding: 5px 10px;
    text-transform: uppercase;
    }
    
    .custom-prg-wrapper {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    }
    
    .prg-success {
    background-color: #63d3a6;
    }
    
    .luminous-wht {
    color: #b5c4d7;
    }
    
    .cs-circle {
    width: 160px;
    height: 160px;
    background: #21365a;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    }
    
    .custom-width-hold {
    max-width: 920px;
    margin: auto;
    }
    
    .pb-100 {
    padding-bottom: 100px;
    }
    
    .pb-50 {
    padding-bottom: 50px;
    }
    
    .pb-75 {
    padding-bottom: 75px;
    }
    
    .text-success {
    color: #007d0c !important;
    }
    
    .bg-success {
    background-color: #159583 !important;
    }
    
    .mcr-success {
    background-color: #63d3a6;
    width: 6px;
    height: 6px;
    display: block;
    border-radius: 30px;
    }
    
    /* #page-topbar {
    position: unset;
    } */
    
    .w-60 {
    width: 60% !important;
    }
    
    .w-70 {
    width: 70% !important;
    }
    
    .text-yellow {
    color: rgb(254 240 138);
    }
    
    .fw-super-bold {
    font-weight: 900;
    }
    
    .fw-strong {
    font-weight: 800;
    }
    
    .summary-width-hold {
    max-width: 1024px;
    margin: auto;
    }
    
    .h-dg-img-wrapper {
    position: absolute;
    left: -50px;
    top: -50px;
    }
    
    .summary-cta {
    margin-left: 310px;
    margin-top: -20px;
    margin-bottom: 30px;
    }
    
    .summary-child-left {
    width: 80%;
    }
    
    .bigger-ft {
    font-size: 36px;
    font-weight: 800;
    }
    
    .circle {
    width: 8px;
    height: 8px;
    border-radius: 30px;
    background-color: #000;
    display: block;
    }
    
    .summary-accordian .accordion-item {
    border: 0 !important;
    }
    
    .summary-accordian .accordion-button {
    background: unset;
    box-shadow: none;
    padding: 0;
    margin-top: 20px;
    font-size: 20px;
    font-weight: 700 !important;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    color: #1a1a1a;
    }
    
    .summary-accordian .accordion-button::after,
    .summary-accordian .accordion-button::before {
    margin-left: unset;
    margin-right: 10px;
    }
    
    .custom-table thead tr th {
    color: #8d9fb6;
    background: #dae7f2;
    font-size: 24px !important;
    font-weight: 800;
    line-height: 25px;
    vertical-align: middle;
    }
    
    .custom-table tbody tr td {
    vertical-align: middle;
    border: 1px solid #dae7f2;
    }
    
    .it-success {
    background-color: #d9f7ed !important;
    color: #63d3a6 !important;
    font-size: 18px;
    }
    
    .sm-cta {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.87) !important;
    cursor: pointer;
    }
    
    .sm-cta a {
    box-shadow: none !important;
    }
    
    .it-danger {
    background-color: #ffdbdb !important;
    color: #ff0000 !important;
    font-size: 18px;
    }
    
    .cs-rsm .select2-selection__control {
    background-color: #dae7f2 !important;
    border-radius: 50px !important;
    }
    
    .assessement-section li.nav-item {
    background: #dae7f2;
    color: #b5c4d7;
    margin-right: 20px;
    font-size: 24px;
    height: 35px;
    width: 52px;
    border-radius: 16px;
    text-align: center;
    }
    
    .assessement-section .nav-tabs-custom {
    border: 0;
    }
    
    .assessement-section .nav-tabs-custom .nav-item .nav-link::after {
    height: 0;
    }
    
    .assessement-section .nav-tabs-custom .nav-item .nav-link.active {
    background-color: unset;
    border: 2px solid #20365b;
    font-size: 24px;
    height: 35px;
    width: 100%;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    }
    
    .assessement-section .nav-tabs-custom .nav-item .nav-link{
    font-weight: 700;
    padding: 0;
    }
    
    .display-attempt2 {
    background: #d9f7ed !important;
    height: 35px;
    width: 52px;
    border-radius: 16px !important;
    }
    
    .display-attempt1 {
    background: #d9f7ed !important;
    color: #63d3a6;
    height: 35px;
    width: 52px;
    border-radius: 16px !important;
    }
    
    .display-attempt_fail {
    background: #ffdbdb !important;
    color: #ec6b66;
    height: 35px;
    width: 52px;
    border-radius: 16px !important;
    }
    
    .display-hold_active {
    color: #ffffff !important;
    background: #20365b !important;
    box-shadow: 0 0 0 4px #a9bfe9;
    height: 35px;
    border-radius: 16px !important;
    }
    
    .assessment-input {
    min-height: 45px;
    width: 200px;
    max-width: 300px;
    padding: 3px 7px !important;
    border: 1px solid gray;
    }
    
    .worng-ans {
    background: #ffdbdb;
    border: 2px solid #ec6b66;
    border-radius: 3px;
    box-shadow: 0 0 0 4px #ffdbdb;
    margin-bottom: 10px;
    }
    
    .correct-ans {
    background: #d9f7ed;
    border: 2px solid #63d3a6;
    border-radius: 3px;
    box-shadow: 0 0 0 4px #d9f7ed;
    margin-bottom: 10px;
    }
    
    .navigator-nxt {
    width: 380px;
    display: flex;
    padding: 30px;
    position: relative;
    background: #ffffff;
    align-items: center;
    border-radius: 24px !important;
    margin-bottom: 12px;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
    rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
    margin-left: auto;
    }
    
    .navigator-wrapper {
    display: flex;
    justify-content: flex-end;
    }
    
    .nv-dg-smile {
    position: absolute;
    }
    
    .nv-dg-smile img {
    width: 190px;
    }
    
    .nv-dg-smile {
    position: absolute;
    right: 340px;
    bottom: 0px;
    z-index: 9;
    }
    
    .navigator-wrapper::before {
    content: "";
    background-image: url(../src/assets/images/services-icon/curve-lft.png);
    position: absolute;
    z-index: 999;
    top: 30px;
    right: 380px;
    width: 40px;
    height: 40px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right bottom;
    }
    
    .btn-cst-success-md {
    padding: 0.25rem 1.5rem;
    border: none;
    color: rgb(255, 255, 255) !important;
    box-shadow: rgb(75, 185, 141) 0px 4px 0px !important;
    background-color: #63d3a6 !important;
    letter-spacing: 0.05em;
    text-transform: uppercase !important;
    border-radius: 8px !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    }
    
    .btn-cst-danger-md {
    padding: 0.25rem 1.5rem;
    border: none;
    color: rgb(255, 255, 255) !important;
    box-shadow: rgb(188, 50, 69) 0px 4px 0px, rgba(146, 45, 63, 0.5) 0px 8px 12px !important;
    background-color: #d83d59 !important;
    letter-spacing: 0.05em;
    text-transform: uppercase !important;
    border-radius: 8px !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    }
    
    .btn-cst-secondary-md {
    padding: 0.25rem 1.5rem;
    border: none;
    color: #8d9fb6 !important;
    box-shadow: hsl(207.5deg 48% 90.2%) 0px 4px 0px !important;
    background-color: #f2f8fd !important;
    letter-spacing: 0.05em;
    text-transform: uppercase !important;
    border-radius: 8px !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    }
    
    ul.dg-selector li {
    padding: 0;
    display: flex;
    align-items: center;
    border-radius: 4px;
    }
    
    li.selector-wrong {
    color: #ae534f;
    background: #ffdbdb !important;
    border: 2px solid #ec6b66;
    display: block;
    box-shadow: 0px 0px 0px 4px #ffdbdb !important;
    }
    
    .border-start-danger {
    border-left: 1px solid #ec6b66;
    }
    
    li.selector-warning {
    color: #237b56;
    background: #d9f7ed !important;
    border: 2px solid #f8b424;
    display: block;
    box-shadow: 0px 0px 0px 4px #ffdbdb !important;
    }
    
    .border-start-warning {
    border-left: 1px solid #f8b424;
    }
    
    .border-start-secondary {
    border-left: 1px solid #b5c4d7;
    }
    
    li.selector-success {
    color: #237b56;
    background: #d9f7ed !important;
    border: 2px solid #63d3a6;
    display: block;
    box-shadow: 0px 0px 0px 4px #d9f7ed !important;
    }
    
    .border-start-success {
    border-left: 1px solid #63d3a6;
    }
    
    .border-start-secondary {
    border-left: 1px solid #b5c4d7;
    }
    
    .emotion-wrapper ul li {
    padding: 0;
    border: 0 !important;
    box-shadow: none !important;
    margin-bottom: 0;
    position: relative;
    }
    
    .emotion-wrapper ul {
    display: flex;
    justify-content: center;
    gap: 40px;
    }
    
    .subject-float {
    position: absolute;
    width: 50%;
    }
    
    .subject-float {
    position: absolute;
    width: 110px;
    left: 50%;
    transform: translate(-50%);
    top: 60px;
    }
    
    .video-wrapper div {
    width: 100% !important;
    height: 550px !important;
    }
    
    .cursor-pointer {
    cursor: pointer;
    }
    
    .dropdown-item {
    padding-inline: 15px;
    }
    
    .profile-wrapper {
    position: relative;
    max-width: 575px;
    width: 100%;
    margin: auto;
    margin-top: 50px;
    }
    
    .cs-card {
    padding: 1.5rem 2rem 1.5rem 2rem;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
    rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
    margin-bottom: 16px;
    }
    
    .w-30 {
    width: 30%;
    }
    
    .obj-fit-cover {
    object-fit: cover;
    }
    
    .image-display-wrapper ul {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 0.5rem 1rem;
    }
    
    .image-display-wrapper1 ul{
      display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 0.5rem 1rem;
    }
    
    
    .image-display-wrapper ul li, .image-display-wrapper1 ul li {
    position: relative;
    border: 1px solid #0000;
    }
    
    .image-display-wrapper ul li.selected, .image-display-wrapper1 ul li.selected {
    box-shadow: 0 0 0 5px #b5c4d7;
    border-radius: 50%;
    border: 1px solid #20365b;
    }
    
    .image-display-wrapper ul li:hover, .image-display-wrapper1 ul li:hover {
    cursor: pointer;
    border: 1px solid #20365b;
    border-radius: 50%;
    }
    
    .slider {
    position: relative;
    width: 100%;
    height: 300px;
    /* Adjust height as needed */
    }
    
    .slide {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    }
    
    .prev,
    .next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 10px 20px;
    background-color: #333;
    color: white;
    border: none;
    cursor: pointer;
    }
    
    .prev {
    left: 10px;
    }
    
    .next {
    right: 10px;
    }
    
    button.next-btn.nav {
    position: absolute;
    left: 51%;
    width: fit-content;
    border: 0;
    background: 0;
    font-size: 16px;
    bottom: -15px;
    }
    
    button.prev-btn.nav {
    position: absolute;
    left: 46% !important;
    width: fit-content;
    border: 0;
    background: 0;
    font-size: 16px;
    bottom: -15px;
    }
    
    .primary-divider {
    height: 4px;
    background: #000;
    margin-top: 30px;
    margin-bottom: 30px;
    }
    
    ul.profile-tab {
    border-bottom: 0;
    }
    
    ul.profile-tab li a {
    background: #fde68a;
    padding: 0.5rem;
    border-radius: 0.5rem !important;
    box-shadow: 0 10px 15px -3px #fde68a, 0 4px 6px -4px #fde68a;
    border: 0 !important;
    }
    
    ul.profile-tab li a span img {
    object-fit: contain;
    }
    
    .set-postion-abs {
    position: absolute;
    }
    
    .profile-tb-ps-1 {
    left: 50%;
    transform: translate(-50%);
    }
    
    .profile-tb-ps-2 {
    right: 70px;
    top: 93px;
    }
    
    .profile-tb-ps-3 {
    right: 70px;
    top: 213px;
    }
    
    .profile-tb-ps-4 {
    left: 70px;
    top: 93px;
    }
    
    .profile-tb-ps-5 {
    left: 70px;
    top: 213px;
    }
    
    /* .avatar-display {
    margin: 70px 0 0 0;
    } */
    
    .avatar-default img {
      margin-top: 40px;
    }
    
    ul.profile-tab li a.active {
    background: #fde68a !important;
    border: 2px solid #2196f3 !important;
    }
    
    .avatar-attachments {
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translate(-50%);
    }
    
    .head-float img {
    width: 130px;
    }
    
    .head-float {
    top: 3px;
    left: -66px;
    }
    
    .dress-float img {
    width: 49px;
    height: 415px;
    object-fit: contain;
    }
    
    .dress-float {
    top: -49px;
    left: -24px;
    }
    
    .companion-float img {
    width: 40px;
    }
    
    .companion-float {
    top: 143px;
    left: 64px;
    }
    
    .hand-float img,
    .hand-float-revert img {
    width: 24px;
    }
    
    .hand-float {
    top: 123px;
    left: -32px;
    }
    
    .hand-float-revert {
    top: 123px;
    left: 10px;
    transform: scaleX(-1);
    }
    
    .weapon-float img {
    width: 44px;
    }
    
    .weapon-float {
    top: 160px;
    left: -36px;
    }
    
    .btn-avt-selected {
    padding: 0.24rem 0.75rem;
    border: 1px solid rgb(218, 231, 242);
    color: rgb(255, 255, 255) !important;
    box-shadow: rgba(149, 167, 189, 0.8) 0px 4px 0px,
    rgb(190, 203, 220) 0px 8px 12px !important;
    background-color: #b5c4d7 !important;
    border-radius: 4px !important;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    }
    
    .btn-avt-select {
    padding: 0.24rem 0.95rem;
    border: 1px solid rgb(218, 231, 242);
    color: rgb(255, 255, 255) !important;
    border-color: #63d3a6 !important;
    box-shadow: rgb(75, 185, 141) 0px 4px 0px !important;
    background-color: #63d3a6 !important;
    border-radius: 4px !important;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    }
    
    .btn-avt-purchase {
    padding: 0.24rem 1.1rem;
    border: 1px solid rgb(218, 231, 242);
    color: rgb(255, 255, 255) !important;
    border-color: #63d3a6 !important;
    box-shadow: rgb(75, 185, 141) 0px 4px 0px !important;
    background-color: #63d3a6 !important;
    border-radius: 4px !important;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    }
    
    .profile-utlities-wrapper ul li{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:10px;
    width: 25%;
    }
    
    .profile-utlities-wrapper ul{
    display: flex;
    flex-wrap: wrap;
    gap: 20px 0;
    }
    
    .dress ul.list-unstyled.profile-utlities li img {
    width: 40px;
    height: 80px;
    object-fit: contain;
    }
    
    button.btn-avt-purchase img {
    height: 15px !important;
    width: 15px !important;
    }
    
    .companion ul.list-unstyled.profile-utlities li img {
    width: 58px;
    height: 80px;
    object-fit: contain;
    }
    
    .weapon ul.list-unstyled.profile-utlities li img {
    width: 105px;
    height: 80px;
    object-fit: contain;
    }
    
    .hand ul.list-unstyled.profile-utlities li img {
    width: 35px;
    height: 80px;
    object-fit: contain;
    }
    
    .head ul.list-unstyled.profile-utlities li img {
    width: 68px;
    height: 80px;
    object-fit: contain;
    }
    
    /* .head-float.female-hd img {
    width: 118px;
    }
    
    .head-float.female-hd {
    top: 6px;
    left: -76px;
    } */
    
    .head-float.female-hd img {
      width: 170px;
    /* width: 70px; */
    }
    
    .head-float.female-hd {
      top: -32px;
      left: -86px;
    /* top: 6px;
    left: -36px; */
    
    }
    
    .my-homework ul li, .my-homework ul a{
    margin-bottom: 0;
    box-shadow: none;
    border-bottom: 1px solid #DAE7F2;
    }
    
    .date-wrapper{
    box-shadow: 1px 0px 0px #D6DDE3, 0px 1px 0px #D6DDE3, -1px 0px 0px #D6DDE3, 0px -1px 0px #D6DDE3, 0px 1px 3px rgba(63,
    63, 68, 0.15);
    background-color: #fff;
    margin-bottom: 34px;
    }
    
    .ms-success{
    background-color: #009688 !important;
    }
    
    .assessment-width-hold{
    max-width: 1150px;
    margin: auto;
    }
    
    .modal-content {
    padding: 1.5rem;
    }
    
    .lg-input{
    padding: 16.5px 14px;
    background-color: #E8F0FF;
    border-radius: 4px;
    border:1px solid #B2B8C3;
    }
    
    .lg-wrapper{
    width: 360px;
    margin: auto;
    }
    
    .lg-wrapper .form-check {
    margin-left: 39px;
    }
    
    .border-bottom-dark{
    border-bottom: 1px solid #8D9FB6;
    }
    
    .lg-wrapper .form-check-input{
    width: 16px;
    height: 16px;
    border: 1px solid #8D9FB6;
    margin-top: 2px;
    margin-right: 12px;
    }
    
    span.vertical-divider {
    height: 20px;
    width: 2px;
    background: #20365b;
    }
    
    .header-item.noti-icon {
    line-height: 2.5;
    }
    
    .text-blue{
    color: #2196f3;
    }
    
    .border-bottom{
    border-bottom: 1px solid #ddd !important;
    }
    
    .border-top{
    border-top: 1px solid #ddd !important;
    }
    
    .custom-pb, .custom-pb-child{
    background-color: unset;
    }
    
    .horizontal-divider{
    width: 100%;
    height: 3px;
    background-color: #f2f8fd;
    margin: 10px 0;
    margin-bottom: 30px;
    }
    
    .custom-table.tb-custom-pd tbody tr td, .custom-table.tb-custom-pd thead tr th {
    padding: 20px !important;
    }
    
    .custom-table.tb-custom-pd thead tr th{
    background-color: #F2F8FD;
    }
    
    .custom-table.tb-custom-pd tbody tr td {
    vertical-align: middle;
    border: 1px solid #f2f8fd;
    }
    
    .custom-mod-width{
    max-width: 600px;
    }
    
    .table.custom-table.noti-table thead tr th {
    font-size: 16px !important;
    background: #f2f8fd;
    }
    
    span.pgb-dk-purple{
    background-color: #5e547a;
    }
    
    .loader-container.body {
    background-color: rgba(224, 240, 246, 0.7) !important;
    z-index: 500 !important;
    }
    
    .loader.box,
    .loader.body {
    background-color: rgba(224, 240, 246, 0.7) !important;
    padding: unset !important;
    border-radius: unset !important;
    }
    
    .px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
    }
    
    .direct_link{
    box-shadow: none !important;
    padding: unset !important;
    margin-bottom: unset !important;
    position: unset !important;
    cursor: pointer;
    }
    
    li.selector-general.active {
    color: #6f42c1;
    /* background: none; */
    border: 2px solid #6f42c1;
    
    box-shadow: 0px 0px 0px 4px #dddbdf !important;
    }
    
    .not_scroll{
    scroll-behavior: unset !important;
    }
    
    .dg-media p {
    margin-bottom: 0;
    }
    
    ul.nav-tabs-custom {
    width: 90%;
    }
    
    .width-controll {
    margin-left: auto;
    }
    
    .width-controll li input {
    display: flex;
    height: 45px;
    }
    
    .answererror {
    margin-left: auto;
    width: 200px;
    background: #ffdbdb;
    border: 2px solid #ec6b66 !important;
    border-radius: 3px;
    box-shadow: 0 0 0 4px #ffdbdb;
    margin-bottom: 10px;
    padding: 3px 7px !important;
    font-style: italic;
    font-size: 16px;
    }
    
    .answersuccess{
      margin-left: auto;
      width: 200px;
      background: #d9f7ed;
      border: 2px solid #63d3a6;
      border-radius: 3px;
      box-shadow: 0 0 0 4px #d9f7ed;
      margin-bottom: 10px;
      padding: 3px 7px !important;
      font-style: italic;
      font-size: 16px;
    }
    
    .answereditor{
    width: 230px !important;
    margin-left: auto;
    padding: 3px 7px !important;
    }
    
    li.input_box.m_input_box {
    box-shadow: none;
    }
    
    .m_feedBack {
      padding: 7px 10px;
      margin-bottom: 15px;
    }
    
    .m_feedBack p {
    margin-bottom: 0;
    }
    
    li.input_box.e_input_box {
      box-shadow: none;
    }
    
    li.input_box.e_input_box .answererror {
      width: 100%;
    }
    
    li.input_box.e_input_box .answereditor {
      width: 100% !important;
    }
    
    li.input_box.e_input_box .answersuccess {
      width: 100% !important;
      background: #d9f7ed;
      border: 2px solid #63d3a6;
      border-radius: 3px;
      box-shadow: 0 0 0 4px #d9f7ed;
    }
    
    .choice-options_wrapper {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      position: relative;
      padding: 10px;
    }
    
    .choice-options_child {
      display: flex;
      align-items: flex-start;
      width: 19%;
      margin-right: 5px;
      line-height: 1;
      margin-bottom: 10px;
    }
    
    .choice-options_child div:first-child {
      margin-right: 5px;
    }
    
    ul.pg-tab {
      margin-right: 50px;
    }
    
    .pg-info .pg-tab li {
      margin-right: 15px;
      cursor: pointer;
    }
    
    .custom-timer {
      float: right;
      margin-top: -54px;
    }
    
    
    .custom-timer h1 {
      font-weight: 800;
      color: #b7bed2;
    }
    
    .custom-timer2 h1 {
      font-weight: 800;
      color: #b7bed2;
    }
    
    
    b, strong{
      font-weight: 800 !important;
      color: #000 !important;
    }
    
    .lb-details ul.nav-tabs-custom.nav-justified {
      width: 100%;
    }
    
    .worksheet-width-hold {
      max-width: 1120px;
      width: 100%;
      margin: auto;
    }
    
    /* canvas#fabric-canvas-1,  .canvas-container {
      width: 100% !important;
    } */
    
    .border-thick{
      border: 10px solid #eee;
    }
    
    .tool-bar-worksheet {
      background-color: rgb(255, 255, 255);
      color: rgba(0, 0, 0, 0.87);
      transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
      overflow: hidden;
      padding: 1rem .75rem;
      margin-top: 0 !important;
    }
    
    .cs-tools button, .fo-select {
      margin-right: 0.5rem;
      height: 40px;
      width: 40px;
      background: none;
      border: 0;
      display: flex;
      align-items: center;
    }
    
    .fs-circle{
      width: 24px;
      height: 24px;
      border: 2px solid #2a2a2a;
      border-radius: 50%;
    }
    
    .fs-rectangle{
      width: 24px;
      height: 18px;
      border: 2px solid #2a2a2a;
    }
    
    .fs-square{
      width: 24px;
      height: 24px;
      border: 2px solid #2a2a2a;
    }
    
    .fo-select button{
      margin-right: 5px;
      padding: 0;
      display: block;
      height: unset;
      width: unset;
    }
    
    .fo-select{
      display: flex;
      align-items: center;
      margin-right: 0.9rem;
    }
    
    .fo-select select {
      width: 15px !important;
      border: 0;
    }
    
    .cs-tools svg {
      width: 26px;
      height: 22px;
    }
    
    .super-size{
      width: 30px !important;
      height: 38px !important;
    }
    
    .color-picker {
      height: 26px;
      width: 28px;
      margin-right: 1rem;
    
    }
    
    .color-picker input {
    width: 100%;
    height: 100%;
    }
    
    .fx-printer{
      width: 26px !important;
      height: 34px !important;
      margin-top: 2px;
    }
    
    .cs-tools button.active,.fo-select.active {
      background: #f2f8fd !important;
    }
    
    .whitespace-nowrap{
      white-space: nowrap;
    }
    
    .progress, .progress-stacked{
      --bs-progress-bg: #dbdbdb !important;
    }
    
    .hw-status div {
    cursor: pointer;
    }
    
    .password-hider{
      position: absolute;
      right: 22px;
      top: 10px;
      cursor: pointer;
    }
    
    .password-hider i{
    color: #4c4c4c;
    }
    
    .e_feedBack p {
      margin: 0;
    }
    
    .e_feedBack {
      padding: 10px;
    }
    .m_feedBack p {
      margin: 0;
    }
    
    .m_feedBack {
      padding: 10px;
    }
    

    /* Kathir changes start*/
    /* Dashboard card gap */
    .cust-rg-gutter.row > * {
        padding-right: calc(var(--bs-gutter-x)* 1.5);
        padding-left: calc(var(--bs-gutter-x)* 1.5);
    }

    /* .custom_hight {
    height: calc(100vh - 650px) !important;
    overflow-y: scroll !important;
    } */
    /* .draggable {
        top: auto !important;
        left: auto !important;
      } */


      .loader-container.inline {
        margin-top: 15px;
        margin-bottom: 15px;
    }


    .kanban-wrapper .dropdown-menu[style] {
        left: unset !important;
        right: 0 !important;
    }

    .text-bg-yellow {
        --tw-bg-opacity: 1;
        background-color: rgb(253 224 71/var(--tw-bg-opacity))
      }
      .text-bg-blue {
        --tw-bg-opacity: 1;
        background-color: #2196F3;
      }
      
      .small_input_box{
        box-shadow: unset !important;
        width: 200px;
        margin-left: auto;
      }


      
.choice-options_wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
    padding: 10px;
  }
  
  .choice-options_child {
    display: flex;
    align-items: flex-start;
    width: 19%;
    margin-right: 5px;
    line-height: 1;
    margin-bottom: 10px;
  }
  
  .choice-options_child div:first-child {
    margin-right: 5px;
  }


  p + p {
    margin-top: 0px;
}

li.input_box.e_input_box textarea {
    height: 40px;
    font-size: 14px;
    font-style: italic;
  }


  .question_container p,
  .question_container div {
    font-size: 18px !important;
    color: black !important;
  }
  

  .active.card-activity, .active.card-home-activity {
    border-color: var(--rs-cyan-200) !important;
    border-radius: 4px;
    border: 4px solid var(--rs-primary-100) !important;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 2px 4px var(--rs-primary-200);
}

.form-check-input[type="checkbox"] {
    border-color: #858585;
    
}
.form-check-input:checked {
    background-color: var(--fc-neutral-text-color);
}
.underline-link {
    text-decoration: underline !important;
    color: blue !important;  /* Optional: Color of the link */
  }

.QuestionBody.jss13 video {
    border: 4px solid var(--fc-border-color);
}

h4.accordion-header{
    line-height: 42px;
}


.activity-card-blue{
    background: #dce1eb !important;
}
.activity-card-danger{
    background: #f5e1e1 !important;
}
.activity-card-success{
    background: #baeeb9 !important;
}

.select2-selection-bold__single-value {
    color: #000 !important;
    opacity: 1 !important;
    font-weight: bold;
}


.HoverCardContent {
	border-radius: 6px;
	padding: 20px;
	width: 300px;
	background-color: white;
	box-shadow:
		hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
		hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
	animation-duration: 400ms;
	animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
	will-change: transform, opacity;
}
.HoverCardContent[data-side="top"] {
	animation-name: slideDownAndFade;
}
.HoverCardContent[data-side="right"] {
	animation-name: slideLeftAndFade;
}
.HoverCardContent[data-side="bottom"] {
	animation-name: slideUpAndFade;
}
.HoverCardContent[data-side="left"] {
	animation-name: slideRightAndFade;
}

.HoverCardArrow {
	fill: white;
}

.Text {
	margin: 0;
	color: #000 !important;
	font-size: 12px;
	line-height: 1.5;
}
.Text.faded {
	color: #000 !important;
}
.Text.bold {
	font-weight: 500;
}

@keyframes slideUpAndFade {
	0% {
		opacity: 0;
		transform: translateY(2px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes slideRightAndFade {
	0% {
		opacity: 0;
		transform: translateX(-2px);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

@keyframes slideDownAndFade {
	0% {
		opacity: 0;
		transform: translateY(-2px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes slideLeftAndFade {
	0% {
		opacity: 0;
		transform: translateX(2px);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}




.sw-child-md {
    transform: scale(0.75);
    display: inline-block;
    margin-left: -5px;
}

    /* Kathir changes END*/