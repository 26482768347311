/* userprofile */

.profile_header {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 0;
}

.headerTimeline {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 0;
}

.Profile_title {
  color: #666e82;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.profileSection-img {
  width: 172px;
  height: 172px;
  object-fit: contain;
  margin-right: 10px;
}

.Profile_lable {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.profile_value {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  overflow-wrap: anywhere;
}

.MyProfile_deviederSection__atQsB {
  border: 1px solid #e2e9f0;
  height: 100vh;
}

.profile_detail_title {
  color: #20365b;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  justify-content: center;
  letter-spacing: 0.3px;
  line-height: normal;
}

.Profile_subtitle {
  color: #404040;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.profile_value {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.profile_value_acc {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.profile_info {
  color: #000 !important;
  font-size: 14px;
  font-style: normal;
  font-weight: bold !important;
  line-height: normal;
}

.border-line {
  border-right: 1px solid #e2e9f0;
  margin-right: 20px;
}

.check_box {
  border-color: #000 !important;
  box-shadow: unset !important;
}

.react-bootstrap-table {
  border-radius: 8px !important;
  box-shadow: none !important;
  overflow: hidden;
  border-bottom: 1px solid #7f7f7f;
}

.right-arr {
  color: #7f7f7f;
  font-size: 20px !important;
  font-weight: 700;
}

.student_label {
  color: #001035;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.2;
}

.student_label_id {
  color: #001035;
  font-size: 12px;
  font-weight: 400;
}

i.fa.fa-circle {
  font-size: 7px !important;
}

i.fa.fa-circle.text-dark.me-1 {
  font-size: 5px !important;
}

.off-can-header {
  background-color: #ffffff;
  padding: 12px 10px;
}

.cstm-off-body {
  padding: 0;
}

.white-contrast {
  padding: 15px 10px;
  background-color: #F9FAFC;
}


.stud_date {
  align-items: center;
  background-color: #dae7f2;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 10px;
  padding: 4px;
}

.offcanvas {
  width: 570px !important;
  background-color: #F0F3FB;
}

.lesson-div {
  padding: 10px;
}

.lesson-nav{
  padding: 12px;
}

.nav-pills .lesson-navlink.active,
.nav-pills .show>.lesson-navlink {
  color: #fff;
  background: #20365b !important;
  font-size: 18px;
  font-weight: 700;
  padding: 10px 0;
  border-radius: 0;
}

.nav-pills .lesson-navlink {
  color: #212545;
  
  font-size: 18px;
  font-weight: 700;
  padding: 10px 0;
  border-radius: 0;
}

.nav-pills>li>a .lesson-navlink .lesson-navlink:hover {
  font-size: 14px;
  color: #212529 !important;
  font-weight: 700;
}







.contact-wrap {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.contact-stu-det {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  justify-content: space-between;
}

.contact-stu-value {
  display: flex;
}

.contact-stu-value label {
  min-width: 150px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.8;
  font-size: 12px;
  margin-bottom: 0;
}

.contact-stu-value span {
  font-weight: 700;
  line-height: 1.8;
  margin-bottom: 0;
  font-size: 12px;
  color: #212529;
}

.studentdetails-report {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.studentdetails-report-values {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}

.studentdetails-report-values label {
  color: #b3b3b3;
  font-size: 15px;
  font-weight: 400;
}

.studentdetails-report-values span {
  color: #212529;
  font-size: 12px;
  font-weight: 700;
}

.high_mastery {
  background-color: #63d3a6;
}

.medium_mastery {
  background-color: #f6911e;
}

.low_mastery {
  background-color: #fb0000;
}

.progress {
  height: 20px !important;
}

.noscore {
  background-color: #b3b3b3;
}

.react-bootstrap-table table.table.table-bordered {
  margin-bottom: 0;
}

.react-bootstrap-table table.table.table-bordered thead {
  margin-bottom: 20px;
}

.topic_mastery {
  background-color: unset;
  height: unset;
}

.topic_mas_label {
  color: #63d3a6;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
}

.topic_mas_para {
  color: #212529;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

/* datatable css */

.cust-head {
  font-size: 15px !important;
  color: #2d2d2d !important;
  font-weight: 700 !important;
}

.main-head {
  border-radius: 8px !important;
  border-bottom: 12px solid #f2f8fd;
}



th.cus-th.cust-head {
  margin-bottom: 20px;
  width: 211px;
  background: rgb(255, 255, 255);
  white-space: nowrap;
  padding: 15px;
}

.entries {
  border-radius: 3px;
  box-shadow: none;
  box-sizing: border-box;
  padding: 0px 0px 0px 6px;
  height: 35px;
  background: white;
  outline: 0px !important;
  border: 0px !important;
}

.option {

  padding: 0px 0px 0px 6px;
  height: 35px;
  background: white;
  outline: 0px !important;
  border: 0px !important;

}

.status-btn {
  background-color: #ea580c !important;
  color: #fff !important;
  font-size: 10px !important;
  border-radius: 0px !important;
}

.page-align {
  text-align: right;
}

th.cus-th.cust-head:first-child {
  border-top-left-radius: 8px;
}

th.cus-th.cust-head:last-child {
  border-top-right-radius: 8px;
}

tr:last-child td:last-child {
  border-bottom-right-radius: 8px;
}

td.cus-td:first-child {
  border-bottom-left-radius: 8px;
}

.report-btn {
  background-color: rgba(0, 16, 53, .122) !important;
  border-radius: 5px;
  padding: 5px;
  color: #212529;
  margin-top: 12px;
  margin-bottom: 12px;
  font-size: 15px;
    font-style: normal;
    font-weight: 700;
}
.rs-anim-fade.rs-anim-in.placement-bottom-start.rs-picker-daterange-menu.rs-picker-popup {
  z-index: 5000;
}